<template>
    <div class="page address-search">
        <div class="header">
            <div class="search-box"
                 :class="{'show-search': searchText.length != 0}">
                <div class="city"
                     @click="switchCity">
                    <div class="text">{{location.city.name}}</div>
                </div>
                <div class="input">
                    <input type="text"
                           placeholder="请输入小区/写字楼/学校等"
                           v-model="searchText"
                           @keydown.enter="searchAddress">
                </div>
                <div class="btn-search"
                     @click="searchAddress">搜索</div>
            </div>
        </div>
        <div class="main">
            <div class="address-list">
                <div class="address-item"
                     @click="clickItem(item, index)"
                     v-for="(item , index) in addressList"
                     :key="index">
                    <div class="name">{{item.title}}</div>
                    <div class="info">{{item.address}}</div>
                    <!-- {{item.shopinfo}} {{item.type}} -->
                </div>
                <Scroll :onBottom="onBottom"
                        :lowerThreshold="600" />
            </div>
        </div>
        <div class="no-list"
             v-if="addressList.length == 0">
            <div class="tip">请在上方输入您的小区/写字楼/学校等</div>
        </div>
    </div>
</template>
<script>
    import { PAGE_SET_CITY } from 'page';
    import { QZ_ADDRESS_SEARCH_NAME } from 'constant';
    import Scroll from '@/components/scroll';
    import City from '@/lib/gaud/city.json';
    let map = null;
    let marker = null;
    // const addressType = '公司企业|商务住宅|地名地址信息|住宿服务|餐饮服务|购物服务|体育休闲服务|科教文化服务';
    const addressType = "汽车服务|汽车销售|汽车维修|摩托车服务|餐饮服务|购物服务|生活服务|体育休闲服务|医疗保健服务|住宿服务|风景名胜|商务住宅|政府机构及社会团体|科教文化服务|交通设施服务|金融保险服务|公司企业|道路附属设施|公共设施";
    export default {
        data() {
            return {
                searchText: '',
                addressList: [],
                selectAddressIndex: -1,
                pageIndex: 1,
                pageSize: 20,
                isBottom: false,
                userSelectOk: false,
                mapContainer: {
                    top: '0px',
                    height: '0px',
                },
                location: { ...this.$store.state.location.selectLocation.city.id ? this.$store.state.location.selectLocation : this.$store.state.location.currentLocation }
            }
        },
        computed: {
        },
        watch: {
            location() {
                this.pageIndex = 1;
                this.addressList = []
                this.getData()
            },
        },
        components: {
            Scroll
        },
        created() {
            if (this.$route.query.lat && this.$route.query.lon) {
                this.location.lon = this.$route.query.lon;
                this.location.lat = this.$route.query.lat;
            }
            this.searchText = window.localStorage.getItem(QZ_ADDRESS_SEARCH_NAME) || '';
            window.localStorage.removeItem(QZ_ADDRESS_SEARCH_NAME);
            if (this.searchText.length > 0) {
                this.pageIndex = 1;
            }
            this.getData(1);
        },
        methods: {
            getData(type = 0) {
                if (this.searchText.length != 0) {
                    this.$util.post('/api/area/searchPoi', {
                        cityName: this.location.city.name,
                        keyword: this.searchText
                    }).then(res => {
                        if (res.success) {
                            if (res.data.length < this.pageSize) {
                                this.isBottom = true;
                            }
                            else {
                                this.isBottom = false;
                            }
                            if (type == 0) {
                                this.addressList = [...this.addressList, ...res.data]
                            }
                            else {
                                this.addressList = res.data;
                            }
                        }
                    })
                }
            },
            clickItem(item, index) {
                this.$router.currentRoute.query.addressInfo = item;
                console.log(item);
                this.$util.pageBack();
            },
            switchCity() {
                window.localStorage.setItem(QZ_ADDRESS_SEARCH_NAME, this.searchText);
                this.$util.pageJump(PAGE_SET_CITY, {
                    type: 3
                })
            },
            searchAddress() {
                this.pageIndex = 1;
                this.getData(1);
            },
            onBottom() {
                if (!this.isBottom) {
                    this.pageIndex++;
                    this.getData();
                }
            }
        }
    }
</script>
<style lang="less">
    @import url('~@/lib/base.less');
    .map-container {
        > .amap-logo,
        > .amap-copyright {
            display: none !important;
        }
    }
</style>

<style lang="less" scoped>
    @import url('~@/lib/base.less');
    @headerInputHeight: 68;
    @headerHeight: 128;
    @mapContainerHeight: 128;
    .page {
        box-sizing: border-box;
        padding-top: .pxToRem(@mapContainerHeight) [];
        background: white;
    }
    .map-container {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        height: .pxToRem(@mapContainerHeight) [];
        z-index: 10;
        .icon-address {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -100%);
            height: .pxToRem(64) [];
            z-index: 100;
        }
        &:after {
            content: ' ';
            position: absolute;
            bottom: 0;
            right: 0;
            left: 0;
            height: .pxToRem(30) [];
            border-top-left-radius: .pxToRem(60) [];
            border-top-right-radius: .pxToRem(60) [];
            background: white;
            z-index: 10;
        }
    }
    .header {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        height: .pxToRem(@headerHeight) [];
        z-index: 11;
        .search-box {
            position: absolute;
            top: .pxToRem(28) [];
            left: .pxToRem(24) [];
            right: .pxToRem(24) [];
            height: .pxToRem(66) [];
            line-height: .pxToRem(66) [];
            border-radius: .pxToRem(33) [];
            background: #d9d9d9;
            transition: all 0.3s;
            &.show-search {
                right: .pxToRem(124) [];
                .btn-search {
                    opacity: 1;
                }
            }
        }
        .city {
            position: absolute;
            top: 0;
            left: 0;
            width: .pxToRem(172) [];
            height: .pxToRem(@headerInputHeight) [];
            line-height: .pxToRem(@headerInputHeight) [];
            text-align: center;
            font-size: .pxToRem(26) [];
            .text {
                .overflow(1);
                margin: 0 auto;
                padding-right: .pxToRem(40) [];
                max-width: .pxToRem(80) [];
                height: .pxToRem(@headerInputHeight) [];
                line-height: .pxToRem(@headerInputHeight) [];
                &:after {
                    content: ' ';
                    .base-center-y;
                    right: 0;
                    .sq(24);
                    background: url('~@/assets/icons/icon-down.png');
                    background-size: contain;
                    background-repeat: no-repeat;
                }
            }
        }
        .input {
            position: absolute;
            left: .pxToRem(172) [];
            right: 0;
            top: 0;
            bottom: 0;
            input {
                width: 100%;
                font-size: .pxToRem(26) [];
                height: .pxToRem(@headerInputHeight) [];
                line-height: .pxToRem(@headerInputHeight) [];
                background: rgba(0, 0, 0, 0);
            }
        }
        .btn-search {
            opacity: 0;
            position: absolute;
            right: .pxToRem(-100) [];
            width: .pxToRem(100) [];
            text-align: center;
            font-size: .pxToRem(26) [];
            color: #d90209;
        }
    }
    .main {
        .address-list {
            .address-item {
                box-sizing: border-box;
                padding: 0 .pxToRem(30) [];
                height: .pxToRem(130) [];
                background: white;
                .name {
                    position: absolute;
                    top: .pxToRem(24) [];
                    left: .pxToRem(80) [];
                    height: .pxToRem(40) [];
                    line-height: .pxToRem(40) [];
                    font-size: .pxToRem(30) [];
                    font-weight: 600;
                    color: rgba(0, 0, 0, 0.9);
                    .overflow(1);
                }
                .info {
                    position: absolute;
                    bottom: .pxToRem(20) [];
                    left: .pxToRem(80) [];
                    height: .pxToRem(36) [];
                    line-height: .pxToRem(36) [];
                    font-size: .pxToRem(24) [];
                    color: rgba(0, 0, 0, 0.5);
                    .overflow(1);
                }
                &:before {
                    content: ' ';
                    position: absolute;
                    top: .pxToRem(28) [];
                    left: .pxToRem(30) [];
                    .sq(32);
                    background: url('~@/assets/icons/icon-location-map.png');
                    background-size: cover;
                }
                &:after {
                    content: ' ';
                    position: absolute;
                    bottom: 0;
                    left: .pxToRem(30) [];
                    right: .pxToRem(30) [];
                    height: .pxToRem(1) [];
                    background: rgba(0, 0, 0, 0.15);
                }
            }
        }
    }
    .no-list {
        margin-top: calc(50vh - .pxToRem(@headerHeight) []);
        width: 100%;
        text-align: center;
        font-size: .pxToRem(32) [];
        color: rgba(0, 0, 0, 0.6);
    }
</style>
