
<template>
    <div class="component-scroll" ref="componentScroll"></div>
</template>
 
<script>
    export default {
        name: 'Scroll',
        data() {
            return {
                isScrollIng: false,
            }
        },
        methods: {
            scrollEvent() {
                let scrollTop = window.parent.document.documentElement.scrollTop || window.parent.document.body.scrollTop;
                if (scrollTop + document.documentElement.clientHeight + this.lowerThreshold >= this.$refs.componentScroll.offsetTop) {
                    if (!this.isScrollIng) {
                        this.isScrollIng = true;
                        this.onBottom();
                    }
                }
                else {
                    this.isScrollIng = false
                }
            }
        },
        props: {
            onBottom: Function,
            lowerThreshold: {
                type: Number,
                default: 100
            }
        },
        mounted() {
            window.addEventListener('scroll', this.scrollEvent, false);
        },
        destroyed() {
            window.removeEventListener('scroll', this.scrollEvent, false);
        }
    }
</script>